
<template>
    <v-container>
       <v-card>
            <v-app-bar
                dark
                color="blue-grey darken-2"
                >
                <v-toolbar-title>Bookings</v-toolbar-title>

                <v-spacer></v-spacer>
                
                <spinner v-if="loading"></spinner> 
                
                <v-spacer></v-spacer>
            </v-app-bar>
                <v-container fluid>
                  <v-row 
                         v-for="(item,index) in this.items" :key="index"
                        class="mt-2"
                        dense>

                      <v-card
                        elevation="2"
                        outlined
                        color="blue-grey lighten-5"
                        
                        min-width="100%">

     
                            <v-card-title>
                              
                                  ID: {{item.id}}
                                      
          

                                  <v-spacer></v-spacer>

                                  <v-btn color="blue" dark
                                    x-small
                                    :to="{ name: 'vendor.booking', params: {id: item.id}}" 
                                    class="routerLink"
                                    target="_blank"
                                  >View booking</v-btn> 

                            </v-card-title>
                    
                        <v-card-subtitle>
                          Number of items requested: {{item.items.length}}
                          
                        </v-card-subtitle>
                        <v-card-text>
                            <v-alert
                                  
                                  type="warning"
                                  v-if="!item.is_approved && !item.rejected"
                                  outlined
                                >
                                  <v-row align="center">
                                    <v-col class="grow">
                                      Order not approved
                                    </v-col>
                                    <v-col class="shrink">
                                          <v-btn color="green" dark
                                            x-small
                                            class="routerLink"
                                            @click="update(item.id,true)"
                                          >
                                          Approve order
                                          </v-btn>
                                          <hr>
                                           <v-btn color="red" dark
                                            x-small
                                            class="routerLink"
                                            @click="update(item.id,false)"
                                          >
                                          Reject order
                                          </v-btn>
                                    </v-col>
                                  </v-row>
                              </v-alert>
                              <span v-else-if="item.is_approved && !item.rejected" style="color: green">
                                Approved
                              </span>
                              <span v-else-if="item.rejected" style="color: red">
                                Rejected
                              </span>
                              
                              <v-divider/>
                              <span>
                                Date reserved
                              <template v-for="(date) in item.booked_dates" >
                                  {{date.date}}
                              </template>
                              </span>

                        </v-card-text>
                        <v-card-actions>
                          Requested by: {{item.user.name}} / {{item.created_at}}
                          


                            <v-spacer/>
                          Total amount: {{item.total | currency}}

                        </v-card-actions>
                      </v-card>
                  </v-row>
                </v-container>
       </v-card>

    </v-container>
</template>


<script>



export default {
  middleware: ['auth','vendor'],
  data() {
        return {
            items: [],
            loading: false,
        }
    },
  mounted(){
    this.getOrders();  
  },
  methods:{
     getOrders () {
      this.loading = true;
      this.$http.get('api/vendor/bookings', { params: this.$route.query })
          .then(({data})  => {
              this.items = data.data
          })
          .catch(error => {
              console.log('Error loading orders data: ' + error)
          }).finally(() => 
          this.loading = false,
          )
      },
      authorize(id,authorize){

              let item = {
                authorize: authorize
              };
              let uri = '/api/vendor/bookings/' + id + '/authorize';
                this.$http.patch(uri,item)
                .then((response) => {
                  this.$toast.success(response.data.message, "Success")
                }).finally(() => 
                  this.getOrders()
                )
      },
      update(id,authorize) {
                this.$toast.question('Are you sure about that?', "Confirm", {
                timeout: 20000,
                close: false,
                overlay: true,
                displayMode: 'once',
                id: 'address',
                zindex: 999,
                title: 'Hey',            
                position: 'center',
                buttons: [
                    ['<button><b>YES</b></button>', (instance, toast) => {
                        
                        this.authorize(id,authorize);

                        instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');

                    }, true],
                    ['<button>NO</button>', function (instance, toast) {

                        instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');

                    }],
                ]            
                });            
      },
  }
}
</script>







